import HcpSignUpValidation from './frmValidation';

export function checkAllFieldValidate(formField: { speciality: string; firstname: string; lastname: string; email: string; zipcode: string; infoAboutIRDs: string; considerationsToGeneticTestingForIRDs: string; emergingScienceAroundIRDs: string; }, form: any, interstedInfo: any, setDisabled: any) {
  return async (inputField: any, inputVal: any) => {
    let newErrors = false;
    for (const key in formField) {
      const field = key;
      const value = fieldValidate(field, inputField, inputVal, form, key);
      const fieldValidation = await HcpSignUpValidation({ field, value, interstedInfo });
      if (fieldValidation.isError === true) {
        newErrors = true;
      }
    }
    newErrors === true ? setDisabled(true) : setDisabled(false);
  };
}
function fieldValidate(field: string, inputField: any, inputVal: any, form: any, key: string) {
  if (field === inputField) {
    return inputVal;
  } else if (form[field] !== undefined) {
    return form[key]
  } else {
    return ""
  }
}

