import React from 'react';
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout";
import { graphql } from 'gatsby';
import { PageWrapper, StayInformedWrapper, HcpMobileBanner, HcpPage } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import PatientSignUpForm from '../../../components/Forms/PatientSignUp';
import HCPSignUpForm from "../../../components/Forms/HCPSignUP"
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import successPopDataBuilder from '@retina-packages/retina-theme-bootstrap/packages/helpers/successPopDataBuilder';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import retinaConfig from '../../../utils/retinaConfigs'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { HTMLRenderer } from "@retina-packages/retina-theme-bootstrap"
import { formPage } from './constants'
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils'

/**
 * Render forms used in site as standard template
 *
 * @param props jsx properties
 * @returns
 */
const FormPageTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const pgContextsFRM = props.pageContext
  const htmlStaticFilesFRM = {
    nonSVGImages: pgContextsFRM.nonSVGImages,
    svgMediaImages: pgContextsFRM.svgMediaImages,
    allMediaDocument: pgContextsFRM.documents
  }
  let topMenuLabel = ""
  let foorMenuLabel = ""
  let bannerTitle = ""
  let topNavTitle = ""
  let footerTitle = ""
  let successPopupLabel = ""
  let exitPopupTitle = ""
  let layoutAttrs
  let addHcpPrefix = false
  let gtmBackToTopLabel = ""
  let footerClassName = ""
  let languageConfig: any = Object.assign({}, retinaConfig.langConfig)

  if (audience === "Professional") {
    topMenuLabel = formPage.hcpMainMenu
    foorMenuLabel = formPage.hcpFooterMenu
    bannerTitle = formPage.hcpBanner
    topNavTitle = formPage.hcpTopNav
    footerTitle = formPage.hcpFooter
    successPopupLabel = formPage.hcpSignupSucessText
    exitPopupTitle = formPage.hcpExitPopup
    layoutAttrs = { ... { ...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs } }
    addHcpPrefix = true
    gtmBackToTopLabel = formPage.backToTopGTMSU
    footerClassName = formPage.footerClassNameHCP
    languageConfig = null
  }
  else {
    topMenuLabel = formPage.patMainMenu
    foorMenuLabel = formPage.patFooterMenu
    bannerTitle = formPage.patBanner
    topNavTitle = formPage.patTopNav
    footerTitle = formPage.patFooter
    successPopupLabel = formPage.patSignupSucessText
    exitPopupTitle = formPage.dtcExitPopup
    layoutAttrs = { ... { ...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs } }
    gtmBackToTopLabel = formPage.backToTopGTMSI
    footerClassName = formPage.footerClassNameDTC
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: topMenuLabel, siteFooterMenu: foorMenuLabel, addHCPPrefix: addHcpPrefix });
  const hcpSwitcherTitle = formPage.hcpSwitcher

  // Reusable components rendering
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs,
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks, bannerTitle: "", carouselTitle: "", audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle });

  // Data for submission success popup data
  const successPopData = blocks.filter((inf: any) => inf?.relationships?.field_reusable_paragraph?.label === successPopupLabel).map(
    (info: any) => {
      return successPopDataBuilder({ info });
    }
  ).at(0);
  const siteLogos: any = deriveSiteLogo({ blocks: blocks, title: formPage.siteLogo })
  const infoProvide = fullHTMLDataBuilder({ blocks: blocks, title: formPage.hcpInfoYouprovide })
  const knowledgePower = fullHTMLDataBuilder({ blocks: blocks, title: formPage.dtcKnowledgePower })
  const bannerHTML = fullHTMLDataBuilder({ blocks: blocks, title: bannerTitle });


  let form;
  let wrapclassname;
  // Render different form based on pathname
  switch (props.location?.pathname) {
    case pgContextsFRM.urlMap["/stay-informed"]?.pathSlug:
    case `${pgContextsFRM.urlMap["/stay-informed"]?.pathSlug}/`:
      wrapclassname = "patient-genetic"
      form = (
        <PatientSignUpForm propdata={props} location={props.location} successPopData={successPopData} audience={audience} staticMediaFiles={htmlStaticFilesFRM} topContentInfo={knowledgePower} />
      )
      languageConfig = Object.assign({}, { ...languageConfig }, { ...formPage.patRedirectLangUrl })
      break;
    case pgContextsFRM.urlMap["/hcp/hcp/sign-up"]?.pathSlug:
    case `${pgContextsFRM.urlMap["/hcp/hcp/sign-up"]?.pathSlug}/`:
      wrapclassname = "hcp-genetic"
      form = (
        <HCPSignUpForm propdata={props} location={props.location} successPopData={successPopData} audience={audience} staticMediaFiles={htmlStaticFilesFRM} topContentInfo={infoProvide} />
      )
      break;
    default:
      form = (
        <></>
      )
      break;
  }

  // Return jsx elements
  return (
    <HcpPage>
      <HcpMobileBanner className={`hcpmobilewrapper ${wrapclassname}`}>
        <StayInformedWrapper className="stayinformedwrapper">
          {props.pageContext !== null && props.pageContext.metaInfo !== null && (
            <MetaTagContainer metaData={props.pageContext.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
            location={props.location}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            exitPopData={exitData}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            staticMediaFiles={htmlStaticFilesFRM}
            siteLogos={siteLogos}
            gtmBackToTopLabel={gtmBackToTopLabel}
            backToTopContent={retinaConfig.backToTopContent}
            hcpValidate={retinaConfig.hcpValidate}
            preIndexUrl={retinaConfig.preIndexUrl}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            showRefContent={retinaConfig.showRefContent}
            hideRefContent={retinaConfig.hideRefContent}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            footerClassName={footerClassName}
            languageConfig={languageConfig}
            {...layoutAttrs}
          >
            <PageWrapper className='pagewrapper patient-pagewrapper stay-informed'>
              <MobileBanner className="mobile-banner clinical-trials-banner">
                <HTMLRenderer data={htmlStaticFilesFRM} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
              </MobileBanner>
              <div className='o-container information-form'>{form}</div>
            </PageWrapper>
          </Layout>
        </StayInformedWrapper>
      </HcpMobileBanner>
    </HcpPage>
  )
}

export default FormPageTemplate;

export const formPageQuery = graphql`
  query MyQuery($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      title
      drupal_internal__nid
      relationships {
        ... formPageQuery
      }
    }
  }
`
