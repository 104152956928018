import { ErrorObj, ErrorMessages } from "../../types";

export interface Props {
	field?: any;
	value?: any;
}

/** Rendering template for Patient Sign Up Validation
 *
 * @param param0 props
 * @returns
 */
const PatientSignUpValidation = async ({ field, value }: Props): Promise<ErrorObj> => {
	let errorObj: ErrorObj = { "isError": false, "field": field, "value": value, "Errormsg": ErrorMessages.noError }
	errorObj = validateFunc({ field, value, errorObj });
	return errorObj;
}

export default PatientSignUpValidation

function validateFunc({ field, value, errorObj }: { field: any; value: any; errorObj: ErrorObj; }): ErrorObj {
	if (field === "email") {
		errorObj = validateEmail(value, errorObj, field);
	}
	if (field === "firstname" && value === "") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.firstnameError };
	}
	if (field === "lastname" && value === "") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.lastnameError };
	}
	if (field === "zipcode") {
		errorObj = validateZipCode(value, errorObj, field);
	}
	if (field === "usertype") {
		if (value === "") {
			errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.userTypeError };
		}
	}
	if (field === "isOver18" && value !== "over18") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.blankError };
	}
	if (field === "isOngoingeducation" && value !== "ongoingeducation") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.blankError };
	}
	if (field === "isSPI" && value !== "spichecked") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.requiredError };
	}
	return errorObj;
}

function validateEmail(value: any, errorObj: ErrorObj, field: any) {
	const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
	if (value === "") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.emailError };
	}
	if (value !== "") {
		const validEmail = emailReg.test(String(value));
		if (!validEmail)
			errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.emailError };
	}
	return errorObj;
}

function validateZipCode(value: any, errorObj: ErrorObj, field: any) {
	const zipcodeReg = /^\d{5}(?:[- ]?\d{4})?$/;
	if (value === "") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.zipcodeError };
	}
	if (value !== "") {
		const validZipcode = zipcodeReg.test(String(value));
		if (!validZipcode)
			errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.zipcodeInvalidError };
	}
	return errorObj;
}

