import axios from 'axios';

export interface Props {
	dataPayLoad: any;
}

/** Rendering template for HCP Sign Up Submission Form
 *
 * @param props props
 * @returns
 */
const HcpSignUpSubmission = async(props: Props) => {
  const { dataPayLoad } = props;

  const leadUrl = `${process.env.HSIGNURL}`
  const bodyFormData = new FormData();
  if (
    (
      dataPayLoad.considerationsToGeneticTestingForIRDs === "" &&
      dataPayLoad.infoAboutIRDs === "" &&
      dataPayLoad.emergingScienceAroundIRDs === "" &&
      dataPayLoad.newTreatmentsforIRDs === ""
    ) ||
    dataPayLoad.speciality === "" ||
    dataPayLoad.firstname === "" ||
    dataPayLoad.lastname === "" ||
    dataPayLoad.email === "" ||
    dataPayLoad.zipcode === ""
  ) {
    return false
  }

  bodyFormData.append('specialty', dataPayLoad.speciality)
  bodyFormData.append('first_name', dataPayLoad.firstname)
  bodyFormData.append('last_name', dataPayLoad.lastname)
  bodyFormData.append('email_address', dataPayLoad.email)
  bodyFormData.append('zip_code', dataPayLoad.zipcode)
  bodyFormData.append('communication_preference_1', "Active")
  bodyFormData.append('communication_preference_2', "Active")

  if (dataPayLoad.infoAboutIRDs !== "") {
    bodyFormData.append('info_about_ird', '1')
  } else {
    bodyFormData.append('info_about_ird', '2')
  }

  if (dataPayLoad.considerationsToGeneticTestingForIRDs !== "") {
    bodyFormData.append('considerations_to_genetic_testing_for_irds', '1')
  } else {
    bodyFormData.append('considerations_to_genetic_testing_for_irds', '2')
  }

  if (dataPayLoad.emergingScienceAroundIRDs !== "") {
    bodyFormData.append('emerging_science_around_irds', '1')
  } else {
    bodyFormData.append('emerging_science_around_irds', '2')
  }

  if (dataPayLoad.newTreatmentsforIRDs !== "") {
    bodyFormData.append('new_treatments_for_irds', '1')
  } else {
    bodyFormData.append('new_treatments_for_irds', '2')
  }

  await axios.post(leadUrl, bodyFormData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  })
  .then(function () {
    return true;
  })
  .catch(function () {
    return false;
  });

  return true;
}

export default HcpSignUpSubmission