import React, { useState, useRef, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { SignUpWrapper } from './styles'
import RetinaModal from "@retina-packages/retina-theme-bootstrap/packages/components/RetinaModal"
import PatientSignUpValidation from './Validation/frmValidation'
import { validateFunc } from "./Validation/validateFunc"
import { submitHandller } from "./Submission/submitHandller"
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap/packages'

type PatFormPropType = {
  propdata: any
  location: any
  successPopData: any
  audience: any
  staticMediaFiles: {
    nonSVGImages: any
    svgMediaImages: any
    allMediaDocument: any
  }
  topContentInfo: string
}

const PatientSignUpForm = (props: PatFormPropType) => {

  const [disabled, setDisabled] = useState(true);
  const [showExitModal, setShowExitModal] = useState(false)
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const formField = { 'isSPI': '', 'firstname': '', 'lastname': '', 'email': '', 'zipcode': '', 'isOver18': '', 'usertype': '', 'referencePath': '', 'isOngoingeducation': '' }
  const [isOver18Checked, setIsOver18Checked] = useState("")
  const [isSPIChecked, setIsSPIChecked] = useState("")
  const [isOngoingeducationChecked, setIsOngoingeducationChecked ] = useState("")
  const isOver18Ref = useRef(null);
  const isOngoingeducationRef = useRef(null);
  const isSPIRef = useRef(null);
  const [notHadGeneticTesting, setNotHadGeneticTesting] = useState(false)
  const [hadGeneticTesting, setHadGeneticTesting] = useState(false)
  const [hadGeneticTestingAndConfirmed, setHadGeneticTestingAndConfirmed] = useState(false)

  const setField = async (event: any, field: any, value: any) => {
    await validateFunc({ setForm, form, field, value, errors, setErrors, event, setIsOver18Checked, setIsOngoingeducationChecked, setIsSPIChecked, validateField, checkAllField })
  }
  const validateField = async (field: any, value: any) => {
    const fieldValidation = await PatientSignUpValidation({ field, value })
    if (fieldValidation.isError) {
      setErrors({
        ...errors,
        [field]: fieldValidation.Errormsg
      })
    }
    await userTypeValidation(field, value, {
      ...errors,
      [field]: fieldValidation.Errormsg
    })
  }

  const userTypeValidation = async (fld: any, val: any, errs: any) => {
    if (fld === "zipcode" || (fld === "isOver18" && val === "")) {
      const field = "usertype";
      const value = (form["usertype"] === undefined) ? "" : form["usertype"];
      const usrTypeValidation = await PatientSignUpValidation({ field, value })
      if (usrTypeValidation.isError) {
        setErrors({
          ...errs,
          [field]: usrTypeValidation.Errormsg
        })
      }
    }
  }
  const checkAllField = async (inputField: any, inputVal: any) => {
    let newErrors = false;
    for (const key in formField) {
      const field = key;
      let value = validateInputFields({ field, inputField, inputVal, form, key });
      if (field === "isOver18") {
        value = isOver18Ref.current.value
      }
      if (field === "isOngoingeducation") {
        value = isOngoingeducationRef.current.value
      }
      if (field === "isSPI") {
        value = isSPIRef.current.value
      }
      const fieldValidation = await PatientSignUpValidation({ field, value })
      if (fieldValidation.isError === true) {
        newErrors = true
      }
    }
    newErrors === true ? setDisabled(true) : setDisabled(false);
  }
  const findFormErrors = async () => {
    setDisabled(true)
    const newErrors = []
    for (const key in formField) {
      const field = key;
      let value = form[key] === undefined ? "" : form[key];
      if (field === "isOver18") {
        value = isOver18Ref.current.value
      }
      if (field === "isOngoingeducation") {
        value = isOngoingeducationRef.current.value
      }
      if (field === "isSPI") {
        value = isSPIRef.current.value
      }
      const fieldValidation = await PatientSignUpValidation({ field, value })
      if (fieldValidation.isError === true) {
        newErrors.push({ "field": [fieldValidation.field], "Errormsg": fieldValidation.Errormsg })
      }
    }
    return newErrors;
  }
  const handleSubmit = async (e: any) => {
    await submitHandller({ e, findFormErrors, setErrors, setDisabled, form, isOver18Ref, isOngoingeducationRef, isSPIRef, setShowExitModal, notHadGeneticTesting, hadGeneticTesting, hadGeneticTestingAndConfirmed })
  }

  useEffect(()=>{
    // Hide the modal on clicking outside.
    document.querySelector('.modal')?.addEventListener('click', (ev: any) => {
      if (ev.target.classList.contains('modal')) {
        setShowExitModal(false)
      }
    })

    const escapeEvent = (e: any) => {
      // Hide modal on pressing escape key for accessibility.
      if (e.key === "Escape") {
        setShowExitModal(false)
      }
    }
    // Hide modal on pressing escape key for accessibility.
    document.addEventListener('keydown', escapeEvent)
  }, [showExitModal])

  return (
    <>
      <RetinaModal
        location={props.location}
        modalData={{ 'description' : props?.successPopData?.description, 'label': 'patient_sign_up_form_success' }}
        show={showExitModal}
        staticMediaFiles={props.staticMediaFiles}
      />

      <SignUpWrapper className="patient-signup-form">
        <div className="sign-up-title form-para o-column--full">
          <Form className="signup-form" onSubmit={handleSubmit} action="#">
            <Form.Group>
              <HTMLRenderer html={props.topContentInfo} data={props.staticMediaFiles} tagName='div'/>
            </Form.Group>
            <div className="o-container-inner">
              <div className="information-form">
                <div className="form signupform">
                  <div className="apos-area">
                    <p className="required-field-text">All fields are required unless noted otherwise.</p>
                    <Form.Group className="custom-check-btn-container check-container-18" controlId="validationSPICheckbox">
                      <Form.Check ref={isSPIRef} className='custom-checkbox-btn check-18 check-bold' label="By providing consent, you agree to the collection and use of your Sensitive Personal Information (SPI). Examples of SPI may include, but are not limited to health related information. We use this information consistent with our Privacy Policy, including to personalize the information you receive, fulfill any requests you submit, and to research, develop, and improve our products and services. By checking the box, you indicate that you read, understand, and agree to such collection and use of your SPI." name="isSPI" type="checkbox" value={isSPIChecked} id="isSPI" isInvalid={!!errors.isSPI} onClick={(e: any) => setField(e, 'isSPI', e.target.value)} />
                      <Form.Control.Feedback type='invalid'>
                        {errors.isSPI}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={'fieldset'} className="checkbox-section optional gtm-form-signup" controlId="validationRadio">
                      <legend className="o-text--normal radio-btn-title">Are you a: *</legend>
                      <Form.Check className='custom-checkbox-btn custom-radio1' id="patientCheckBox" label="Patient" name="usertype" type="radio" value="patient" isInvalid={!!errors.usertype} onChange={(e: any) => { setField(e, 'usertype', e.target.value); }} aria-required="true" />
                      <Form.Check className='custom-checkbox-btn custom-radio2' id="caregiverCheckBox" label="Caregiver" name="usertype" type="radio" value="caregiver" isInvalid={!!errors.usertype} onChange={(e: any) => { setField(e, 'usertype', e.target.value); }} aria-required="true" />
                      <Form.Check className='custom-checkbox-btn custom-radio3' id="otherCheckBox" label="Other" name="usertype" type="radio" value="other" isInvalid={!!errors.usertype} onChange={(e: any) => { setField(e, 'usertype', e.target.value); }} aria-required="true" />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.usertype}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="apos-area-widget-wrapperp" controlId="formFirstName">
                      <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>First name *</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control name="firstName" type="text" className='form-field__input' isInvalid={!!errors.firstname} onBlur={(e: any) => setField(e, 'firstname', e.target.value)} aria-required="true" />
                        <Form.Control.Feedback type='invalid' role="alert">
                          {errors.firstname}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="apos-area-widget-wrapperp" controlId="formLastName">
                      <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Last name *</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control name="lastName" type="text" className='form-field__input' isInvalid={!!errors.lastname} onBlur={(e: any) => setField(e, 'lastname', e.target.value)} aria-required="true" />
                        <Form.Control.Feedback type='invalid' role="alert">
                          {errors.lastname}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="validationEmail">
                      <div className="form-field__control gtm-form-signup">
                        <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Email address *</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control name="email" type="text" className='form-field__input' isInvalid={!!errors.email} onBlur={(e: any) => setField(e, 'email', e.target.value)} aria-required="true" />
                          <Form.Control.Feedback type='invalid' role="alert">
                            {errors.email}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </div>
                    </Form.Group>

                    <Form.Group className="zipcode" controlId="formZipCode">
                      <Form.Label className="o-column--full o-text--normal apos-forms-label form-field__label">ZIP Code *</Form.Label>
                      <Form.Control type="tel" maxLength="10" className="form-field__input" isInvalid={!!errors.zipcode} onBlur={(e: any) => { setField(e, 'zipcode', e.target.value); }} />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.zipcode}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <p className="o-text--normal radio-btn-title radio-title">What is your experience with genetic testing for inherited retinal diseases? (Optional)</p>
                    <Form.Group className="checkbox-section optional gtm-form-signup patient-custom-check" controlId="validationCheckbox1">
                      <Form.Check className='custom-checkbox-btn custom-radio1' label="I have not had genetic testing" name="expGeneticTesting" type="radio" value="notHadGeneticTesting" onClick={(e: any) => { setNotHadGeneticTesting(!notHadGeneticTesting) }} />
                    </Form.Group>
                    <Form.Group className="checkbox-section optional gtm-form-signup patient-custom-check" controlId="validationCheckbox2">
                      <Form.Check className='custom-checkbox-btn custom-radio2' label="I have had genetic testing but received an inconclusive or negative result" name="expGeneticTesting" type="radio" value="hadGeneticTesting" onClick={(e: any) => { setHadGeneticTesting(!hadGeneticTesting) }} />
                    </Form.Group>
                    <Form.Group className="checkbox-section optional gtm-form-signup patient-custom-check" controlId="validationCheckbox3">
                      <Form.Check className='custom-checkbox-btn custom-radio3' label="I have had genetic testing and confirmed my gene variant (a change in the DNA sequence that makes up my genes)" name="expGeneticTesting" type="radio" value="hadGeneticTestingAndConfirmed" onClick={(e: any) => { setHadGeneticTestingAndConfirmed(!hadGeneticTestingAndConfirmed) }} />
                    </Form.Group>
                    <hr ></hr>

                    <Form.Group className="custom-check-btn-container check-container-18" controlId="validationCheckbox">
                      <Form.Check ref={isOver18Ref} className='custom-checkbox-btn check-18 check-bold' label="I am 18 years or older. *" name="isOver18" type="checkbox" value={isOver18Checked} id="isOver18" isInvalid={!!errors.isOver18} onClick={(e: any) => setField(e, 'isOver18', e.target.value)} aria-required="true" />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.isOver18}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <p className="o-text--normal radio-btn-title radio-title"><strong>I want to register for: *</strong></p>
                    <Form.Group className="custom-check-btn-container check-container-18" controlId="validationCheckbox">
                      <Form.Check ref={isOngoingeducationRef} className='custom-checkbox-btn check-18 check-reg' label="Ongoing education and support on inherited retinal diseases, including genetic testing and the latest therapies" name="isOngoingeducation" type="checkbox" value={isOngoingeducationChecked} id="isOngoingeducation" isInvalid={!!errors.isOngoingeducation} onClick={(e: any) => setField(e, 'isOngoingeducation', e.target.value)} aria-required="true" />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.isOngoingeducation}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3" controlId="validationReferencePath">
                    <Form.Control type="text" name="referencePath" onChange={(e: any) => { setField(e, 'referencePath', e.target.value); }} />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className=" o-container o-column-btn-container patient-submit-btn-container">
              <Button className="o-button o-button--primary form-field__submit gtm-form-signup mb-6" type="submit" disabled={disabled} >
              Submit
              </Button>
            </div>
          </Form>
        </div>
      </SignUpWrapper>
    </>
  )

}

export default PatientSignUpForm;

function validateInputFields(
  { field, inputField, inputVal, form, key }: {
    field: string;
    inputField: any;
    inputVal: any;
    form: any;
    key: string
  }): any {
  if (field === inputField) {
    return inputVal
  } else if (form[field] !== undefined) {
    return form[key]
  } else {
    return ""
  }
}

