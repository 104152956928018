import HcpSignUpSubmission from '.';
import { ErrorMessages } from '../../types';

export async function submitHandller({ e, findFormErrors, setErrors, setDisabled, form, info, consider, emerging, newTreatment, setShowExitModal }: { e: any; findFormErrors: () => Promise<{ field: string[]; Errormsg: ErrorMessages; }[]>; setErrors: any; setDisabled: any; form: any; info: any; consider: any; emerging: any; newTreatment:any; setShowExitModal: any; }): Promise<void> {
  e.preventDefault();
  if (form.referencePath !== undefined && form.referencePath !== "") {
    return;
  }
  const newErrors = await findFormErrors();
  if (Object.keys(newErrors).length > 0) {
    const errorObject = newErrors.reduce((obj, item: {
      field: any;
      Errormsg: string;
    }) => ({
      ...obj,
      [item.field]: item.Errormsg
    }), {});
    setErrors(errorObject);
  } else {
    setDisabled(true);
    const dataPayLoad = {
      'speciality': form.speciality, 'firstname': form.firstname, 'lastname': form.lastname, 'email': form.email, 'zipcode': form.zipcode, 'infoAboutIRDs': info, 'considerationsToGeneticTestingForIRDs': consider, 'emergingScienceAroundIRDs': emerging, 'newTreatmentsforIRDs': newTreatment
    };
    const result = await HcpSignUpSubmission({ dataPayLoad });
    if (result === true) {
      setShowExitModal(true);
      // Time to render modal.
      const renderTime = 1000;
      setTimeout(()=> {
        const modalCloseButtons = document.querySelectorAll('.modal-thankyou .button-close, .modal-thankyou .btn-close-link');
        for (let i = 0; i < modalCloseButtons.length; i++) {
          const el = modalCloseButtons.item(i);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          el?.addEventListener('click', (event: any) => {
            setShowExitModal(false);
          });
        }
      }, renderTime);

    }
  }
}
