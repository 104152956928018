import styled from "styled-components";
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const SignUpWrapper = styled.div`

    .o-column--full{
        width: 100%;
        .signup-form{
            width: 45%;
            margin: auto;
        }

        .o-container-hcp {
            max-width: 1188px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 3rem;
        }
        .form-header-container{
            max-width: 1188px;
            margin: 0 auto;
            padding: 0 3rem;

            .sign-up-form {
                padding: 8rem 0 5rem;

								@media ${device.laptopMedium} {
									padding: 30px 0 0 0;
    							width: 100%;
								}

                .signup-top-paragraph {
                    width: 80%;
                    margin: auto;
                    p{
                        font-size: 2.2rem;
                        line-height: 3.2rem;
                        text-align: center;
                        color: #000;
                        font-weight: 600;
                        font-family: "Gotham";
                    }
                }

                p{
                    color: #000000;
                    margin: 0 auto 20px;
                    font-family: "Gotham Book";
                }

                .o-paragraph{
                    p{
                        font-size: 2.2rem;
                        font-weight: 500;
                        line-height: 3.2rem;
                        color: #000000;
                        width: 80%;
                        margin: 0 auto 20px;
                        font-family: "Gotham-Book";

                        a:hover{
                            text-decoration:none;
                        }
                    }
                  
                }
                .o-paragraph {
                    a{
                        color: #000000;
                    }
                }
            }
            
            .o-text-center{
                text-align:center;
            }

            .information-form {
                padding-bottom: 120px;
                font-family: "Gotham-Book";
                
                .form-required {
                    p{
                        color: #000000;
                        font-size: 1.6rem;
                        margin-bottom: 15px;                       
                    }
                }
                .optional-form-dw{
                    &:valid{
                        
                    }        
                }

                .form-field__label{
                    display: inline-block;
                    font-weight: 500;
                    color: #000000;
                    text-align: left;
                    font-family: Gotham, sans-serif;
                }

                .topic-text{
                    p{
                        font-family:Gotham; 
                        width:101%;
                    }  
                    &.invalid-topic {
                        .invalid-feedback {
                            display: block;
                        }
                        .form-check-input {
                            border: 3px solid #E00707 !important;
                        }
                    }                 
                }
                .o-text--normal {
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    text-align: left;

                    @media ${device.ipadLandscape} {
                        font-size: 1.4rem;
                        line-height: 1.8rem; 
                    }
                }

                .signup-form{
                    select{
                        font-size: 18px;
                        border-radius: 4;
                        border-radius: 4px;
                        border: 1px solid #8000BE;
                        padding: 15px 16px;
                        width: 70%;
                        appearance: none;
                        position: relative;                        
                        background-position-x: 97%;
                    } 
                    small{
                        display:none;
                    } 

                    input{
                        font-size: 18px;
                        border-radius: 4;
                        border-radius: 4px;
                        border: 1px solid #8000BE;
                        padding: 14px 15px;
                        margin-top: 10px;
                    }

                    .custom-input{
                        input{
                            margin-top: -5px;
                        }                       
                    }
                }

                .dw-province {
                    width: 100%;
                    select{
                        font-size: 18px;
                        border-radius: 4;
                        border-radius: 4px;
                        border: 1px solid #8000BE;
                        padding: 15px 16px;
                        width: 100% ;
                        appearance: none;
                        position: relative;                        
                        background-position-x: 97%;

                        &:focus-visible {
                            outline: -webkit-focus-ring-color auto 1px !important;
                        }
                    }
                }

                .o-button:disabled {
                    background-color: #8000BE;
                    opacity: 0.3;
                    pointer-events: none;
                }

                .o-button {
                    font-size: 2.2rem;
                    font-weight: 500;
                    line-height: 3.2rem;
                    text-decoration: none;
                    z-index: 1;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    overflow: hidden;
                    padding: 10px 25px;
                    border: none;
                    letter-spacing: 0;
                    border-radius: 6px;
                    background: transparent;
                    transition: all 0.3s ease;
                    cursor: pointer;
                    margin: auto;
                    text-align: center;
                    display: block;
                    margin-top: 48px;
                    font-family: 'Gotham';

                    &:focus {
                        outline: solid 3px #5391fa !important;
                    }
                }

                .o-button:hover{
                    background:#FFF;
                    transition: ease 0.3ms;                 
                    color: #8000BE;
                    border-color: #fff;
                    font-weight: 700;
                }

                .o-button--primary {
                    background: #8000BE;
                    color: #fff;
                    border: 1px solid #fff;
                }
            }
        }
    }

    .apos-area{
        .check-optional{
            small{
                display:none;
            }
            input {
                border: 2px solid #8000be !important;
            }
        }
        .apos-area-widget-wrapperp{
            margin: 48px 0 0 0;

            .invalid-feedback{
                font-size: 14px;
                margin-top: 10px;
                font-weight: 700;
                color: #E00707;
                display: block;
            }
            
            .gtm-form-signup{
                .input-group{
                    width:70%;
                }
            }

            .form-control.is-invalid:focus, .form-control:invalid:focus, .optional-dw-hcp:focus{
                box-shadow: unset !important;
                border-color: unset;
            }

            .input-group{
                .form-control{
                    &:invalid{
                        background:transparent;
                    }

                    &:valid{
                        background:transparent;
                    }
                    
                }
            }
            

            &:first-child{
                margin:0 ;
            }

            .radio-btn-title{
                font-weight: 500;
                color: #000000;
                text-align: left; 
            }

            label{
                font-weight: 500;
                color: #000000;
                text-align: left;
            }
            .form-check-input{
                margin-left: -15px;
                border: solid 2px #8000be;
            }

            .form-check {
                @media ${device.laptopMedium} {
                    padding-left: 15px !important;
                }
            }
            .custom-checkbox-btn{  
                margin-top: 30px;
                margin-bottom: 5px;

                input[type="checkbox"]:checked {
                    border: solid 2px #8000be;
                    background-color: #8000be;
                }

                input[type="checkbox"]{
                    margin-top:-5px;
                    height: 32px;
                    width: 32px;
                }

                input[type="checkbox"]:focus{
                    border-color: blue;
                    box-shadow: 0 0 1px rgb(0 0 255 / 25%);
                }

                .form-check-input{
                    position: absolute;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                label{
                    display: block;
                    position: relative;
                    padding-left: 36px;
                    margin-top: 30px;
                    cursor: pointer;
                    font-size: 22px;
                    user-select: none;
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    padding-top: 0;
                }

                .form-check-input{
                    &:valid~.form-check-label {
                     font-weight: 500;
                     color: #000000;
                     text-align: left;
                     }
                 }
            }
        }

        .form-field__input{ 
            font-size: 18px;
            border-radius: 4;
            border-radius: 4px;
            border: 1px solid #8000BE;
            padding: 15px 16px;

            &:focus-visible {
                outline: -webkit-focus-ring-color auto 1px !important;
            }
        }
    }

    @media (max-width: 1359.98px){
        .o-container-hcp {
            padding: 0 5rem;
            max-width: 892px ;
        }
    }

    @media ${device.ipadLandscape} {

        .o-container-hcp {
            max-width: 718px ;
            font-size: 2rem;
            line-height: 2.6rem;
        }

        .sign-up-form {
            padding-bottom: 0 !important;
        }

        .form-required {
            color: #000000;
            font-size: 1.6rem;
            margin-bottom: 15px;
        }
        .apos-area-widget-wrapperp {
            margin-top: 2rem !important;
        }

        .form-header-container {
            max-width: 618px !important;
            padding: 0 0 !important;
        }

        .signup-top-paragraph {
            p {
                font-size: 2.2rem;  
            }

            @media ${device.laptopMedium} {
                width: 100% !important;
            }
        }
        .o-paragraph {
            p {
                width:100% !important;
                font-size: 2rem !important;
                line-height: 2.6rem !important;
            }
        }

        .signup-form {
            width: 100% !important;
        }

        .information-form {
            max-width: 718px !important;
            padding-bottom: 48px;
            max-width: 718px;
            padding: 0 0;
            font-size: 2rem;
            line-height: 2.6rem;
            margin: auto;
        }
    }
    
`;