import { ErrorObj, ErrorMessages, SignUpFields } from "../../types"

export interface Props {
	field?: any
	value?: any
	interstedInfo?: any
}

/** Rendering template for HCP Sign Up Validation 
 * 
 * @param param0 props
 * @returns 
 */
const HcpSignUpValidation = async ({ field, value, interstedInfo }: Props): Promise<ErrorObj> => {
	let errorObj: ErrorObj = { "isError": false, "field": field, "value": value, "Errormsg": ErrorMessages.noError }
	errorObj = validateFields({ field, value, errorObj, interstedInfo })
	return errorObj
}

export default HcpSignUpValidation
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function validateFields({ field, value, errorObj, interstedInfo }: { field: any; value: any; errorObj: ErrorObj; interstedInfo: any; }): ErrorObj {
	const errors: ErrorObj = { "isError": false, "field": field, "value": value, "Errormsg": ErrorMessages.noError}
	switch (field) {
		case SignUpFields.EmailAddr:
			validateEmail(value, errors);
			break; 
		case SignUpFields.ZipCode:
			validateZipCode(value, errors);
			break; 
		case SignUpFields.Firstname:
			errors.Errormsg = (value === "") ? ErrorMessages.firstnameError : ErrorMessages.noError
			errors.isError = (value === "")
			break
		case SignUpFields.Lastname:
			errors.Errormsg = (value === "") ? ErrorMessages.lastnameError : ErrorMessages.noError
			errors.isError = (value === "")
			break
		case SignUpFields.Speciality:
			errors.Errormsg = (value === "") ? ErrorMessages.specialityError : ErrorMessages.noError
			errors.isError = (value === "")
			break; 
		case SignUpFields.InfoAboutIRDs:
		case SignUpFields.EmergingScienceAroundIRDs:
		case SignUpFields.ConsiderationsToGeneticTestingForIRDs:
		case SignUpFields.NewTreatmentsforIRDs:
			validateInterests(interstedInfo, errors)			
			break; 
		default:
			errors.Errormsg = ErrorMessages.noError
			errors.isError = false
			break;
	}
	return errors
}

/** Helper function for Zip Code validation 
 * 
 * @param value object
 * @param errors object
 *
 * @returns void
 */
function validateZipCode(value: any, errors: ErrorObj) {
	const zipcodeReg = /^\d{5}(?:[- ]?\d{4})?$/;
	const validZipCode = zipcodeReg.test(String(value));
	errors.Errormsg = (!validZipCode || value === "") ? ErrorMessages.zipcodeError : ErrorMessages.noError;
	errors.isError = (!validZipCode || value === "");
}

/** Helper function for Email validation 
 * 
 * @param value object
 * @param errors object
 *
 * @returns void
 */
function validateEmail(value: any, errors: ErrorObj) {
	const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
	const validEmail = emailReg.test(String(value));
	errors.Errormsg = (!validEmail || value === "") ? ErrorMessages.emailError : ErrorMessages.noError;
	errors.isError = (!validEmail || value === "");
}

/** Helper function for Interest validation 
 * 
 * @param interstedInfo object
 * @param errors object
 *
 * @returns void
 */
function validateInterests(interstedInfo: any, errors: ErrorObj) {
	let i = 0
	for (const key in interstedInfo) {
		const val = interstedInfo[key]
		if (val !== "") {
			i++
		}
	}
	if (i === 0) {
		errors.Errormsg = ErrorMessages.interestError
		errors.isError = true
	}
}
