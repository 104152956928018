import PatientSignUpSubmission from '.'
import { ErrorMessages } from "../../types"

export async function submitHandller({ e, findFormErrors, setErrors, setDisabled, form, isOver18Ref, isOngoingeducationRef, isSPIRef, setShowExitModal, notHadGeneticTesting, hadGeneticTesting, hadGeneticTestingAndConfirmed }: { e: any; findFormErrors: () => Promise<{ field: string[]; Errormsg: ErrorMessages; }[]>; setErrors: any; setDisabled: any; form: any; isOver18Ref: any; isOngoingeducationRef: any;  isSPIRef: any; setShowExitModal: any; notHadGeneticTesting: boolean; hadGeneticTesting: boolean; hadGeneticTestingAndConfirmed: boolean; }): Promise<void> {
  e.preventDefault()
  if (form.referencePath !== undefined && form.referencePath !== "") {
    return;
  }
  const newErrors = await findFormErrors()
  if (Object.keys(newErrors).length > 0) {
    const errorObject = newErrors.reduce((obj, item: {
      field: any
      Errormsg: string
    }) => ({
      ...obj,
      [item.field]: item.Errormsg
    }), {})
    setErrors(errorObject)
  } else {
    setDisabled(true)

    await submitForm({ form, isOver18Ref, isOngoingeducationRef, isSPIRef, notHadGeneticTesting, hadGeneticTesting, hadGeneticTestingAndConfirmed, setShowExitModal });
  }
}

async function submitForm({ form, isOver18Ref, isOngoingeducationRef, isSPIRef, notHadGeneticTesting, hadGeneticTesting, hadGeneticTestingAndConfirmed, setShowExitModal }: { form: any; isOver18Ref: any; isOngoingeducationRef: any; isSPIRef: any; notHadGeneticTesting: boolean; hadGeneticTesting: boolean; hadGeneticTestingAndConfirmed: boolean; setShowExitModal: any; }) {
  const result = await PatientSignUpSubmission({
    email: form.email,
    firstName: form.firstname,
    lastName: form.lastname,
    zipcode: form.zipcode,
    isOver18: isOver18Ref.current.value === 'over18' ? true : false,
    isOngoingeducation: isOngoingeducationRef.current.value === 'ongoingeducation' ? true : false,
    isSPI: isSPIRef.current.value === 'spichecked' ? true : false,
    usertype: form.usertype,
    notHadGeneticTesting: notHadGeneticTesting,
    hadGeneticTesting: hadGeneticTesting,
    hadGeneticTestingAndConfirmed: hadGeneticTestingAndConfirmed
  });
  if (result === true) {
    setShowExitModal(true);
    // Modal render time.
    const renderTime = 1000;
    setTimeout(() => {
      const modalCloseButtons = document.querySelectorAll('.modal-thankyou .button-close, .modal-thankyou .btn-close-link');
      for (let i = 0; i < modalCloseButtons.length; i++) {
        const el = modalCloseButtons.item(i);
        el.addEventListener('click', () => {
          setShowExitModal(false);
        });
      }
    }, renderTime);
  }
}

