export async function validateFunc({ setForm, form, field, value, errors, setErrors, event, setIsOver18Checked, setIsOngoingeducationChecked, setIsSPIChecked, validateField, checkAllField }: { setForm: any; form: any; field: any; value: any; errors: any; setErrors: any; event: any; setIsOver18Checked: any; setIsOngoingeducationChecked: any; setIsSPIChecked: any; validateField: (field: any, value: any) => Promise<void>; checkAllField: (inputField: any, inputVal: any) => Promise<void>; }): Promise<any> {
  setForm({
    ...form,
    [field]: value
  });
  if (!!errors[field])
    setErrors({
      ...errors,
      [field]: null
    });

  if (field === "isOver18") {
    value = event.target.checked === true ? "over18" : "";
    await setIsOver18Checked(value);
  }

  if (field === "isOngoingeducation") {
    value = event.target.checked === true ? "ongoingeducation" : "";
    await setIsOngoingeducationChecked(value);
  }

  if (field === "isSPI") {
    value = event.target.checked === true ? "spichecked" : "";
    await setIsSPIChecked(value);
  }

  await validateField(field, value);
  await checkAllField(field, value);
  return value;
}
