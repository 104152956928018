export enum SignUpFields {
	EmailAddr = "email",
	Province = "province",
	Speciality = "speciality",
	Firstname = "firstname",
	Lastname = "lastname",
	InfoAboutIRDs = "infoAboutIRDs",
	ConsiderationsToGeneticTestingForIRDs = "considerationsToGeneticTestingForIRDs",
	EmergingScienceAroundIRDs = "emergingScienceAroundIRDs",
	NewTreatmentsforIRDs = "newTreatmentsforIRDs",
	IsOver18 = "",
	AgreedTerms = "",
	ZipCode = "zipcode"
}

export enum ErrorMessages {
	requiredError = "This field is required.",
	licenseNoError = "This field is required.",
	generalError = "This field is required.",
	emailError = "Please enter a valid email address",
	provinceError = "This field is required.",
	specialityError = "Please select a specialty",
	firstnameError = "Please enter your first name",
	lastnameError = "Please enter your last name",
	checkFieldError = "Please select at least one topic.",
	agreedFieldError = "This field is required.",
	zipcodeError = "Please enter your ZIP Code",
	zipcodeInvalidError = "Please enter your ZIP Code",
	userTypeError = "Please select one",
	interestError = "Please select one",
	noError = "",
	blankError = " "
}

export type ErrorObj = {
	isError: boolean
	field: string
	value: string
	Errormsg: ErrorMessages
}
