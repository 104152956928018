import React, { useState, useRef, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { SignUpWrapper } from './styles'
import HcpSignUpValidation from './Validation/frmValidation'
import RetinaModal from "@retina-packages/retina-theme-bootstrap/packages/components/RetinaModal"
import validateFunc from "./Validation/validateFunc"
import { submitHandller } from "./Submission/submitHandller"
import { hcpValidation } from "./Validation/hcpValidation"
import { checkAllFieldValidate } from "./Validation/checkAllFieldValidate"
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap/packages'


type FormPropType = {
  propdata: any
  location: any
  successPopData: any
  audience: any
  staticMediaFiles: {
    nonSVGImages: any
    svgMediaImages: any
    allMediaDocument: any
  }
  topContentInfo: string
}

const HCPSignUpForm = (props: FormPropType) => {
  const [disabled, setDisabled] = useState(true);
  const [showExitModal, setShowExitModal] = useState(false)
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const infoAboutIRDsRef = useRef(null);
  const considerationsToGeneticRef = useRef(null);
  const emergingScienceAroundIRDsRef = useRef(null);
  const newTreatmentsforIRDsRef = useRef(null);
  const formField = { 'speciality': '', 'firstname': '', 'lastname': '', 'email': '', 'zipcode': '', 'infoAboutIRDs': '', 'considerationsToGeneticTestingForIRDs': '', 'emergingScienceAroundIRDs': '', 'newTreatmentsforIRDs': '', 'referencePath': '' }
  const interstedInfoArr = { 'infoAboutIRDs': '', 'considerationsToGeneticTestingForIRDs': '', 'emergingScienceAroundIRDs': '', 'newTreatmentsforIRDs': '' }
  const [interstedInfo, setInterstedInfo] = useState(interstedInfoArr)
  const [infoAboutIRDsChecked, setInfoAboutIRDsChecked] = useState("")
  const [geneticTestingForIRDsChecked, setGeneticTestingForIRDsChecked] = useState("")
  const [emergingScienceIRDsChecked, setEmergingScienceIRDs] = useState("")
  const [newTreatmentsforIRDsChecked, setNewTreatmentsforIRDs] = useState("")


  const setField = async (event: any, field: any, value: any) => {
    await validateFunc({ setForm, form, field, value, errors, setErrors, event, interstedInfo, setInfoAboutIRDsChecked, setGeneticTestingForIRDsChecked, setEmergingScienceIRDs, setNewTreatmentsforIRDs, setInterstedInfo, validateField, checkAllField })
  }

  const validateField = hcpValidation(interstedInfo, setErrors, errors)

  const checkAllField = checkAllFieldValidate(formField, form, interstedInfo, setDisabled)

  const findFormErrors = async () => {
    setDisabled(true)
    const newErrors = []
    for (const key in formField) {
      const field = key;
      const value = form[key] === undefined ? "" : form[key];
      const fieldValidation = await HcpSignUpValidation({ field, value, interstedInfo })
      if (fieldValidation.isError === true) {
        newErrors.push({ "field": [fieldValidation.field], "Errormsg": fieldValidation.Errormsg })
      }
    }
    return newErrors;
  }

  const handleSubmit = async (e: any) => {
    const consider = considerationsToGeneticRef?.current?.value
    const info = infoAboutIRDsRef?.current?.value
    const emerging = emergingScienceAroundIRDsRef?.current?.value
    const newTreatment = newTreatmentsforIRDsRef?.current?.value
    await submitHandller({ e, findFormErrors, setErrors, setDisabled, form, info, consider, emerging, newTreatment, setShowExitModal })
  }

  useEffect(() => {
    // Hide the modal on clicking outside.
    document.querySelector('.modal')?.addEventListener('click', (ev: any) => {
      if (ev.target.classList.contains('modal')) {
        setShowExitModal(false)
      }
    })
    const escapeEvent = (e: any) => {
      // Hide modal on pressing escape key for accessibility.
      if (e.key === "Escape") {
        setShowExitModal(false)
      }
    }
    // Hide modal on pressing escape key for accessibility.
    document.addEventListener('keydown', escapeEvent)
  }, [showExitModal])
  return (
    <>
      <RetinaModal
        location={props.location}
        redirectlink={"/"}
        modalData={{ "headerTxt": "", "description": props?.successPopData?.description, "footerTxt": "", "modalclass": "HCP-signup", "label": "hcp_sign_up_form_success" }}
        show={showExitModal}
        staticMediaFiles={props.staticMediaFiles}
        closeButtonLabel={'Close'}
      />
      <SignUpWrapper className="signupwrapper">
        <div className="sign-up-title form-para o-column--full">
          <HTMLRenderer html={props.topContentInfo} data={props.staticMediaFiles} tagName='div' />

          <div className="o-container-hcp form-header-container">
            <div className="information-form">
              <Form className="signup-form" onSubmit={handleSubmit} action="#">
                <div className="form-required"><p>All fields are required.</p></div>
                <div className="apos-area">
                  <Form.Group className="apos-area-widget-wrapperp" controlId="formSpeciality">
                    <div className="form-field__control gtm-form-signup">
                      <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Specialty</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Select name="speciality" size="sm" className="apos-forms-input form-field__input optional-form-dw optional-dw-hcp"
                          isInvalid={!!errors.speciality}
                          onChange={(e: any) => setField(e, 'speciality', e.target.value)}
                          aria-required="true">
                          <option value="">Select a specialty</option>
                          <option value="IRD Specialist">IRD Specialist</option>
                          <option value="Retina Specialist">Retina Specialist</option>
                          <option value="Low Vision Specialist">Low Vision Specialist</option>
                          <option value="Genetic Counselor">Genetic Counselor</option>
                          <option value="Pediatric Ophthalmologist">Pediatric Ophthalmologist</option>
                          <option value="Ophthalmologist">Ophthalmologist</option>
                          <option value="Optometrist">Optometrist</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid' role="alert">
                          {errors.speciality}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </div>
                  </Form.Group>

                  <Form.Group className="apos-area-widget-wrapperp" controlId="formFirstName">
                    <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>First name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control name="firstName" type="text" className='form-field__input' data-gtm-event-label="First Name"
                        isInvalid={!!errors.firstname}
                        onBlur={(e: any) => setField(e, 'firstname', e.target.value)}
                        aria-required="true" />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.firstname}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="apos-area-widget-wrapperp" controlId="formLastName">
                    <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Last name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control name="lastName" type="text" className='form-field__input' data-gtm-event-label="Last Name"
                        isInvalid={!!errors.lastname}
                        onBlur={(e: any) => setField(e, 'lastname', e.target.value)}
                        aria-required="true" />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.lastname}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="apos-area-widget-wrapperp" controlId="formEmail">
                    <div className="apos-area-widget-wrapperp">
                      <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Email address</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control name="email" type="email" className='form-field__input' data-gtm-event-label="Email Address"
                          isInvalid={!!errors.email}
                          onBlur={(e: any) => setField(e, 'email', e.target.value)}
                          aria-required="true" />
                        <Form.Control.Feedback type='invalid' role="alert">
                          {errors.email}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3 zipcode" controlId="formZipCode">
                    <Form.Label className="o-column--full o-text--normal apos-forms-label form-field__label">ZIP Code</Form.Label>
                    <Form.Control type="tel" maxLength="10" className="form-field__input" data-gtm-event-label="Zip Code"
                      isInvalid={!!errors.zipcode}
                      onBlur={(e: any) => { setField(e, 'zipcode', e.target.value); }}
                      aria-required="true" />
                    <Form.Control.Feedback type='invalid' role="alert">
                      {errors.zipcode}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={`apos-area-widget-wrapperp check-optional topic-text ${errors.interestedReceiveInfo ? 'invalid-topic' : ''}`} controlId="formCheckbox">
                    <p className="o-text--normal radio-btn-title"> What topics are you interested in receiving information about? (select all that apply)</p>

                    <Form.Check ref={infoAboutIRDsRef} className='custom-checkbox-btn checkbox-section register-checkbox' label="Info about IRDs (disease education, diagnostic challenges)" name="infoAboutIRDs" type="checkbox" id="check-infoAboutIRDs" value={infoAboutIRDsChecked} isInvalid={!!errors.considerationsToGeneticTestingForIRDs} onClick={(e: any) => setField(e, 'infoAboutIRDs', e.target.value)} aria-required={true} />

                    <Form.Check ref={considerationsToGeneticRef} className='custom-checkbox-btn' label="Considerations to genetic testing for IRDs (why test, how to test, working with the care team)" name="considerationsToGeneticTestingForIRDs" type="checkbox" id="check-considerationsToGeneticTestingForIRDs" value={geneticTestingForIRDsChecked} isInvalid={!!errors.considerationsToGeneticTestingForIRDs} onClick={(e: any) => setField(e, 'considerationsToGeneticTestingForIRDs', e.target.value)} aria-required={true} />

                    <Form.Check ref={emergingScienceAroundIRDsRef} className='custom-checkbox-btn' label="Emerging science around IRDs (clinical trials, gene discoveries, genetic innovations)" name="emergingScienceAroundIRDs" type="checkbox" id="check-emergingScienceAroundIRDs" value={emergingScienceIRDsChecked} isInvalid={!!errors.emergingScienceAroundIRDs} onClick={(e: any) => setField(e, 'emergingScienceAroundIRDs', e.target.value)} aria-required={true} />

                    <Form.Group className="mb-3" controlId="validationReferencePath">
                      <Form.Control type="text" name="referencePath" onChange={(e: any) => { setField(e, 'referencePath', e.target.value); }} />
                    </Form.Group>

                    <Form.Control.Feedback type='invalid' role='alert'>
                      {errors.interestedReceiveInfo}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <Button id="submitButton" className="o-button o-button--primary form-field__submit gtm-form-HCP-Signup" data-gtm-event-label="Submit" type="submit" disabled={disabled} >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </SignUpWrapper>
    </>
  )
}

export default HCPSignUpForm;
