import axios from 'axios'

export interface Props {
  email: string;
  firstName: string
  lastName: string
	zipcode: string
	isOver18: boolean
  isOngoingeducation: boolean
  isSPI: boolean
  usertype: string
  notHadGeneticTesting: boolean
  hadGeneticTesting: boolean
  hadGeneticTestingAndConfirmed: boolean
}

/** Rendering template for Patient Sign Up Submission Form
 *
 * @param props props
 * @returns
 */
const PatientSignUpSubmission = async(props: Props) => {
  const { email, zipcode, isOver18, isOngoingeducation, isSPI, usertype, notHadGeneticTesting, hadGeneticTesting, hadGeneticTestingAndConfirmed, firstName, lastName } = props;

  const leadUrl = `${process.env.PSIGNURL}`
  const bodyFormData = new FormData();

  if (!email || !zipcode) {
    return false
  }

  bodyFormData.append('email_address', email)
  bodyFormData.append('first_name', firstName)
  bodyFormData.append('last_name', lastName)
  bodyFormData.append('zip_code', zipcode)
  bodyFormData.append('i_am_18_years_or_older', (isOver18) ? "1" : "0")
  bodyFormData.append('ongoing_education_support_optin', (isOngoingeducation) ? "1" : "0")

  if (usertype === 'patient') {
    bodyFormData.append('are_you_a_', "5373")
  }
  else if (usertype === 'caregiver') {
    bodyFormData.append('are_you_a_', "269")
  }
  else if (usertype === 'other') {
    bodyFormData.append('are_you_a_', "89")
  } else {
    // do nothing
  }

  if (notHadGeneticTesting) {
    bodyFormData.append('what_is_your_experience_with_genetic_testing_ird', '5441')
  } else if (hadGeneticTesting) {
    bodyFormData.append('what_is_your_experience_with_genetic_testing_ird', '5442')
  } else if (hadGeneticTestingAndConfirmed) {
    bodyFormData.append('what_is_your_experience_with_genetic_testing_ird', '5443')
  } else {
    // noops
  }

  bodyFormData.append('communication_preference_1', 'Active');
  bodyFormData.append('communication_preference_2', 'Active');
  if (isSPI) {
    bodyFormData.append('communication_preference_3', 'Active');
  } else {
    // noops
  }

  await axios({
    method: "post",
    url: leadUrl,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function () {
      //handle success
      return true
    })
    .catch(function () {
      //handle error
      return false
    });

  return true

}

export default PatientSignUpSubmission
